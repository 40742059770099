

@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'); */
/********** Template CSS **********/
:root {
    --primary: #25245D;
    --secondary: #845EC2;
    --light: #eaeaff;
    --dark: #091E3E;
    --white: #ffffff;
    --black: #000000;
}
body{
    font-family: "Lato", sans-serif;
}
h1, h2, h3, h4, h5, h6{
    font-family: "Barlow Condensed", sans-serif;
}
a:hover{
    text-decoration: none;
}
.bg-primary {
    background-color: var(--primary) !important;
}
.bg-secondary {
    background-color: var(--secondary) !important;
}
.btn-primary {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary)
}
.btn-primary:hover {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}
.btn-secondary {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary)
}
.btn-secondary:hover {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}
.bg-primary .btn-secondary:hover {
    border-color: var(--secondary);
}
.text-primary {
    color: var(--primary) !important;
}
.bg-light {
    background-color: var(--light) !important;
}

/* HTML: <div class="loader"></div> */
.react-loader {
    width: 50px;
    --b: 8px; 
    aspect-ratio: 1;
    border-radius: 50%;
    padding: 1px;
    background: conic-gradient(#0000 10%, var(--primary)) content-box;
    -webkit-mask:
      repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
      radial-gradient(farthest-side,#0000 calc(100% - var(--b) - 1px),#000 calc(100% - var(--b)));
    -webkit-mask-composite: destination-in;
            mask-composite: intersect;
    animation:l4 1s infinite steps(10);
  }
  @keyframes l4 {to{transform: rotate(1turn)}}


.logo-primary{
  display: none;
}
.sticky-top .logo-primary{
    display: block;
}
.sticky-top .logo-white{
    display: none;
}

/*** Heading ***/
h1,
h2,
.fw-bold {
    font-weight: 800 !important;
}

h3,
h4,
.fw-semi-bold {
    font-weight: 700 !important;
}

h5,
h6,
.fw-medium {
    font-weight: 600 !important;
}

a{
    text-decoration: none;
}

/*** Button ***/
.btn {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    transition: .5s;
    border-radius: 10px;
}

.btn-primary,
.btn-secondary {
    color: #FFFFFF !important;
    box-shadow: inset 0 0 0 50px transparent;
}

.btn-primary:hover {
    box-shadow: inset 0 0 0 0 var(--primary);
    color: #fff;
}

.btn-secondary:hover {
    box-shadow: inset 0 0 0 0 var(--secondary);
}

.btn-square {
    width: 36px;
    height: 36px;
}

.btn-sm-square {
    width: 30px;
    height: 30px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}
.input-group .input-group-append {
    right: 9px;
    z-index: 11;
}
.certificate-field .action-icon-btn{
   display: none;
}


/*** Navbar ***/
.navbar-dark .navbar-nav .nav-link {
    font-family: 'Nunito', sans-serif;
    position: relative;
    margin-left: 25px;
    padding: 35px 0;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    outline: none;
    transition: .5s;
    text-transform: capitalize;
}

.sticky-top.navbar-dark .navbar-nav .nav-link {
    padding: 20px 0;
    color: var(--dark);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    color: var(--primary);
}

.navbar-dark .navbar-brand h1 {
    color: #FFFFFF;
}

.navbar-dark .navbar-toggler {
    color: var(--primary) !important;
    border-color: var(--primary) !important;
}

@media (max-width: 991.98px) {
    .logo-primary {
        display: block;
    }
    img.logo-white{
        display: none;
    }
    .sticky-top.navbar-dark {
        position: fixed;
        background: #FFFFFF;
        z-index: 9999;
        width: 100%;
    }

    .navbar-dark .navbar-nav .nav-link,
    .navbar-dark .navbar-nav .nav-link.show,
    .sticky-top.navbar-dark .navbar-nav .nav-link {
        padding: 10px 0;
        color: var(--dark);
    }

    .navbar-dark .navbar-brand h1 {
        color: var(--primary);
    }
}

@media (min-width: 992px) {
    .navbar-dark {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        border-bottom: 1px solid rgba(256, 256, 256, .1);
        z-index: 999;
    }
    
    .sticky-top.navbar-dark {
        position: fixed;
        background: #FFFFFF;
        z-index: 9999;
    }

    .navbar-dark .navbar-nav .nav-link::before {
        position: absolute;
        content: "";
        width: 0;
        height: 2px;
        bottom: -1px;
        left: 50%;
        background: var(--primary);
        transition: .5s;
    }

    .navbar-dark .navbar-nav .nav-link:hover::before,
    .navbar-dark .navbar-nav .nav-link.active::before {
        width: 100%;
        left: 0;
    }

    .navbar-dark .navbar-nav .nav-link.nav-contact::before {
        display: none;
    }

    .sticky-top.navbar-dark .navbar-brand h1 {
        color: var(--primary);
    }
}


/*** Carousel ***/
#header-carousel .carousel-item {
    min-height: calc(100vh - 100px);
}
#header-carousel .carousel-item img{
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: center center;
}
.carousel-caption {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(9, 30, 62, .7);
    z-index: 1;
}

@media (max-width: 767px) {

#navbarCollapse {
    position: fixed;
    left: 0;
    top: 0;
    width: 280px;
    background: #fff;
    height: 100%;
    transform: translateX(-100%);
    transition: all 0.3s ease-in-out;
}

#navbarCollapse.show {
    transform: translateX(0);
}
#navbarCollapse select.form-control {
    margin-left: 24px;
    margin-top: 10px;
}
    .hero-banner img {
        width: 100%;
    }
    .testimonial-slider .swiper{
        overflow: unset;
    }
    .testimonial-sec{
        overflow: hidden;
    }
    .service-item {
        margin-bottom: 15px;
    }
    #header-carousel .carousel-item {
        min-height: 400px;
    }
    #header-carousel .carousel-item img{
        width: 100%;
        min-height: 400px;
        object-fit: cover;
        object-position: center center;
    }
    .carousel-caption h5 {
        font-size: 14px;
        font-weight: 500 !important;
    }

    .carousel-caption h1 {
        font-size: 30px;
        font-weight: 600 !important;
    }
}

.carousel-control-prev,
.carousel-control-next {
    width: 10%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 3rem;
    height: 3rem;
}



/*** Service ***/
.service-item {
    position: relative;
    height: 320px;
    padding: 0 30px;
    transition: .5s;
    border-radius: 10px;
}

.service-item .service-icon {
    margin-bottom: 30px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary);
    border-radius: 2px;
    transform: rotate(-45deg);
    border-radius: 10px !important;
}

.service-item .service-icon i {
    transform: rotate(45deg);
}

.service-item a.btn {
    position: absolute;
    width: 60px;
    bottom: -48px;
    left: 50%;
    margin-left: -30px;
    opacity: 0;
    border-radius: 8px;
}

.service-item:hover a.btn {
    bottom: -24px;
    opacity: 1;
}


/*** Testimonial ***/
.testimonial-carousel .owl-dots {
    margin-top: 15px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.testimonial-carousel .owl-dot {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    width: 15px;
    height: 15px;
    background: #DDDDDD;
    border-radius: 2px;
    transition: .5s;
}

.testimonial-carousel .owl-dot.active {
    width: 30px;
    background: var(--primary);
}

.testimonial-carousel .owl-item.center {
    position: relative;
    z-index: 1;
}

.testimonial-carousel .owl-item .testimonial-item {
    transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
    background: #FFFFFF !important;
    box-shadow: 0 0 30px #DDDDDD;
}


/*** Team ***/
.team-item {
    transition: .5s;
}

.team-social {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
}

.team-social a.btn {
    position: relative;
    margin: 0 3px;
    margin-top: 100px;
    opacity: 0;
}

.team-item:hover {
    box-shadow: 0 0 30px #DDDDDD;
}

.team-item:hover .team-social {
    background: rgba(9, 30, 62, .7);
}

.team-item:hover .team-social a.btn:first-child {
    opacity: 1;
    margin-top: 0;
    transition: .3s 0s;
}

.team-item:hover .team-social a.btn:nth-child(2) {
    opacity: 1;
    margin-top: 0;
    transition: .3s .05s;
}

.team-item:hover .team-social a.btn:nth-child(3) {
    opacity: 1;
    margin-top: 0;
    transition: .3s .1s;
}

.team-item:hover .team-social a.btn:nth-child(4) {
    opacity: 1;
    margin-top: 0;
    transition: .3s .15s;
}

.team-item .team-img img,
.blog-item .blog-img img  {
    transition: .5s;
    max-height: 400px;
    object-fit: cover;
}

.team-item:hover .team-img img,
.blog-item:hover .blog-img img {
    transform: scale(1.15);
}


/*** Miscellaneous ***/
@media (min-width: 991.98px) {
    .facts {
        position: relative;
        margin-top: -75px;
        z-index: 1;
    }
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
}

/* .bg-header {
    background: linear-gradient(rgba(9, 30, 62, .7), rgba(9, 30, 62, .7)), url(../img/carousel-1.jpg) center center no-repeat;
    background-size: cover;
} */

.link-animated a {
    transition: .5s;
}

.link-animated a:hover {
    padding-left: 10px;
}



.ver-step-item {
    position: relative;
    isolation: isolate;
}
.ver-step-item:after {
    position: absolute;
    content: "";
    width: 2px;
    border-right: 2px dashed var(--primary);
    left: 22px;
    top: 50%;
    height: 100%;
    z-index: -1;
}
.ver-step-item:last-child:after{
    display: none;
}
.step-num {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white);
    border-radius: 2px;
    transform: rotate(-45deg);
    border: 2px dashed var(--primary);
}

.step-num span{
    transform: rotate(45deg);
}
.ver-steps{
  list-style: none;
  padding: 0;
  margin: 0;
}
.accordion .accordion-item {
    border: none;
    margin: 10px 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid var(--primary);
    border-radius: 8px;
}
.accordion-button{
    color: var(--primary);
    font-weight: 600;
}
.accordion-button:not(.collapsed){
    color: #fff;
    background-color: var(--primary);
}
.accordion-button:focus{
    box-shadow: none;
}


footer .menu a {
    color: #fff;
}
footer .menu a:hover {
    opacity: 0.7;
}
.cta-img{
    max-width: 301px;
    margin-top: -90px;
}
.cstm-cta-card {
    margin-bottom: -100px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
}
.contact-form .form-control {
    height: 50px;
}
.contact-form textarea.form-control {
    height: 120px;
}
.contact-form .form-group {
    margin-bottom: 15px;
}
.counter-up:after {
    content: "+";
    top: -10px;
    position: relative;
}



/* responsive css */
@media (max-width: 768px) {
    .cta-img{
        margin-top: 0;
    }
}


.hero-banner, .inner-banner{
  background-image: url('assets/bg.png');
  background-color: #000035 !important;
  background-position: center center;
  background-size: cover;
}
.testimonial-slider {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.testimonial-card {
  background: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial-card img.client-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.testimonial-card h3 {
  font-size: 18px;
  margin-bottom: 5px;
  color: #333;
}

.testimonial-card .designation {
  font-size: 14px;
  color: #777;
  margin-bottom: 15px;
}

.testimonial-card .review {
  font-size: 16px;
  line-height: 1.5;
  color: #555;
}
img.form-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 5px;
}
/* Highlight the center slide */
.swiper-slide-active .testimonial-card {
  transform: scale(1.1); /* Slightly enlarge */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Add a stronger shadow */
  background: #fff; /* Make it stand out */
  border-color: var(--primary) /* Add a highlight color */
}

.testimonial-slider .swiper {
    padding: 30px 10px;
    margin-bottom: 20px;
    padding-bottom: 50px;
}
.testimonial-slider .swiper-pagination{
    bottom: 0px;
}
.role-list {
    padding: 0;
    list-style: none;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.role-list li {
    padding: 20px;
    display: flex;
}

.role-list li .role-card {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #e5e5e5;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #e5e5e5;
}
.role-list li .role-card:hover{
    border-color: var(--primary);
}
span.role-icon {
    width: 70px;
    height: 70px;
    background: var(--primary);
    border-radius: 50%;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}
span.role-icon svg {
    width: 38px;
}
main.main-layout {
    padding-top: 67px;
}
.form-group sup {
    color: red;
}

.dashboard-sidebar {
    position: sticky;
    top: 80px;
}
.sidebar-menu-list{
    margin: -5px 0px;
}
.sidebar-menu-list > li{
    padding: 5px 0px;
    list-style: none;
}
.sidebar-menu-list > li > .nav-link {
    background: var(--primary);
    color: #fff;
    border-radius: 5px;
    padding: 14px;
    text-align: center;
}
.sidebar-menu-list > li > .nav-link:hover, .sidebar-menu-list > li > .nav-link.active {
    background: var(--secondary);
}
.Toastify__toast-container.Toastify__toast-container--top-center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999;
    top: 0;
}
.product-detail .swiper-slide img{
    width: 100%;
    border-radius: 10px;
}
.swiper-thumbs{
    margin-top: 20px;
}
.swiper-thumbs .swiper-slide img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    object-position: center center;
    border-radius: 7px;
    border: 1px solid #d1d1d1;
}
.swiper-thumbs .swiper-slide.swiper-slide-thumb-active img{
    border-color: var(--primary);
}
.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef !important;
    opacity: 0.7;
}
form .form-control {
    padding: 10px 15px;
    min-height: 50px;
    border: 1px solid #d2d2fb !important;
}
form textarea.form-control {
    min-height: 120px;
    resize: none;
}
.action-btn {
    display: flex;
    margin: 0 !important;
    align-items: center;
}
.hide-del-icon button{
  display: none;
}
.action-btn .btn{
  margin-top: 0px !important;
}
.img-wrap {
    position: relative;
}

.img-wrap .action-icon-btn {
    position: absolute;
    right: 2px;
    opacity: 0.8;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 4px;
    
}
.cstm-list{
padding: 0px;
padding-left: 18px;
}
.cstm-list li{
    font-size: 18px;
    padding: 10px 0px;
}
.form-group.certificate-file button.action-icon-btn {
    display: none;
}
.react-tel-input .form-control{
    width: 100% !important;
}
figure.preview-image {
    display: inline-block;
    position: relative;
}

figure.preview-image button.btn.btn-danger {
    position: absolute;
    top: 0;
    right: 8px;
    border-radius: 4px;
    opacity: 0.7;
}
figure.preview-image button.btn.btn-danger:hover{
    opacity: 1;
}
.news-section .card img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    object-position: center center;
}
.news-section .card h5.card-title {
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.news-section .card p.card-text{
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    min-height: 73px;
}